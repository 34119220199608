import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import './contact.css';
import { TextField, Grid } from '@mui/material';

export default function Contact() {
  return (
    <div className="shadow p-3 mb-5 bg-body rounded">
      <div style={{ backgroundColor: 'white' }}>
        <Container fluid>
          <Row id="contact-wrapper">
            <Col md={6} className="d-flex justify-content-center align-items-center mt-3" id='map'>
              
                <iframe
                  className='map-align'
                  src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d1008141.6674533162!2d75.97388006430224!3d9.239523372631542!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3b05bbcafe933311%3A0xec1760eda90f0966!2skserc!3m2!1d8.5070214!2d76.9616162!5e0!3m2!1sen!2sin!4v1690026142354!5m2!1sen!2sin"
                  width="100%" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
             
            </Col>
            <Col md={6} className="mt-5">
              <div className="formcontact mt-4">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField label="Name" fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField label="Phone" fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField label="Place" fullWidth />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField label="Email" fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Message"
                      fullWidth
                      multiline
                      rows={4}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} container justifyContent="center">
                    <button type="button" className="btn btn-outline-primary">Submit</button>
                  </Grid>
                </Grid>
              </div>
            </Col>
          </Row>
        </Container>

        <Container>
          <div className='details'>
            <Row className='office-address mt-5' id='contact-row'>
              <h3 className='our-address-head'>Contact Details</h3>
              <Col md={4} className='address-img-container'>
                <img className='contact-img-office' src={require('../../img/Gallery/office1.jpeg')} alt="Office" />
              </Col>
              <Col md={4}>
                <p className='address-text'>GX46+RJ8, K.P.F.C. Bhavanam, <br /> CV Raman Pillai Rd,
                  Vellayambalam, <br /> Thiruvananthapuram, Kerala 695010</p>
                <img width="22" height="22" src="https://img.icons8.com/external-flatart-icons-solid-flatarticons/64/external-telephone-contact-flatart-icons-solid-flatarticons-1.png" alt="telephone" />
                <span className='fax-text'><a href="tel:04712735544">0471 2735544</a></span>
                <br />
                <img width="22" height="22" src="https://img.icons8.com/ios-filled/50/fax.png" alt="fax" />
                <span className='fax-text'><a href="tel:04712735599">0471-2735599</a></span>
                <br />
                <img width="22" height="22" src="https://img.icons8.com/ios-filled/50/secured-letter--v1.png" alt="email" />
                <span className='fax-text'><a href="mailto:kserc@erckerala.org">kserc@erckerala.org</a></span>
                <br />
                <img width="24" height="24" src="https://img.icons8.com/material-rounded/24/earth-planet--v2.png" alt="website" />
                <span className='fax-text'><a href="https://www.erckerala.org" target="_blank" rel="noopener noreferrer">www.erckerala.org</a></span>
                <br />
              </Col>
              <Col md={4} className='secretary'>
                <h5>Secretary</h5>
                <p className='fax-text' id='secretary-name'>Shri.Satheeshchandran C R</p>
                <img width="22" height="22" src="https://img.icons8.com/material/48/phone--v1.png" alt="phone" />
                <span className='detail-text'><a href="tel:+919446354423">+91 9446354423</a></span>
                <br />
                <img width="22" height="22" src="https://img.icons8.com/external-flatart-icons-solid-flatarticons/64/external-telephone-contact-flatart-icons-solid-flatarticons-1.png" alt="telephone" />
                <span className='detail-text'><a href="tel:04712735544">0471 2735544 (Common)</a></span>
                <br />
                <img width="22" height="22" src="https://img.icons8.com/external-flatart-icons-solid-flatarticons/64/external-telephone-contact-flatart-icons-solid-flatarticons-1.png" alt="telephone" />
                <span className='detail-text'><a href="tel:04712735588">0471 2735588 (Direct)</a></span>
                <br />
                <img width="22" height="22" src="https://img.icons8.com/ios-filled/50/secured-letter--v1.png" alt="email" />
                <span className='detail-text'><a href="mailto:secretarykserc@gmail.com">secretarykserc@gmail.com</a></span>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
}
