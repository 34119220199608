import Navn from 'react-bootstrap/Nav';
import Navbart from 'react-bootstrap/Navbar';
import '../Navbar/navbar2.css'; // Import your CSS file
import { Link } from 'react-router-dom';

export default function Navbar() {
  return (
    <div className='top'>
      <Navbart expand="lg" className="navbar-back-colur navbar-dark">
        <Navbart.Toggle aria-controls="basic-navbar-nav" />
        <Navbart.Collapse id="basic-navbar-nav">
          <Navn style={{fontSize:'14px'}} className="me-auto ms-auto">
            <Link style={{width:'80px',wordWrap:'break-word'}}   role='button' className='nav-text-home nav-link' to="/">ഹോം</Link>
             <Link style={{width:'90px',wordWrap:'break-word'}} role='button' className='nav-text-home nav-link' to="/gallery">ഗ്യാലറി</Link>
            <Link style={{width:'90px',wordWrap:'break-word'}}  role='button' className='nav-text-home nav-link ' to="/righttoinformation">വിവരാവകാശം</Link>
            <Link style={{width:'100px',wordWrap:'break-word'}} role='button' className='nav-text-home nav-link ' to="/dashboard">ഡാഷ്‌ബോർഡ്</Link>
            <Link style={{width:'180px'}} role='button' className='nav-text-home nav-link ' to="/state_coordination">സ്റ്റേറ്റ് കോഓർഡിനേഷൻ ഫോറം</Link>
            <Link style={{width:'130px'}} role='button' className='nav-text-home nav-link ' to="/stateadvisory" >സ്റ്റേറ്റ് അഡ്വൈസറി കമ്മിറ്റി</Link>
            <Link style={{width:'90px',wordWrap:'break-word'}} role='button' className='nav-text-home nav-link ' to="/startup" >സംരംഭകത്വം</Link>
            {/* <Link role='button' className='nav-text-home nav-link' to="/entrepreneur" >Entrepreneur</Link> */}
            <Link style={{width:'110px',wordWrap:'break-word'}}  role='button' className='nav-text-home nav-link ' to="/careerspage">തൊഴിലവസരങ്ങൾ</Link>
            <Link style={{width:'100px',wordWrap:'break-word'}}  role='button' className='nav-text-home nav-link ' to="/archives">ആർക്കൈവുകൾ</Link>
            <Link style={{width:'100px',}} role='button' className='nav-text-home nav-link ' to="/events">ഇവെന്റ്സ്</Link>
            <div className="dropdown">
              <button style={{width:'90px',wordWrap:'break-word',marginLeft:'0',}} className="dropdown-toggle" type="button" id="dropdownMenuButton" data-mdb-toggle="dropdown" aria-expanded="false">
                അറിയുക
              </button>
              <ul style={{paddingRight:'10px',marginRight:'20px'}} className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li><Link className="dropdown-item" to="/commission">കമ്മീഷൻ</Link></li>
                <li><Link className="dropdown-item" to="organisation">സംഘടന</Link></li>
                <li><Link className="dropdown-item" to="history">ഹിസ്റ്ററി</Link></li>
              </ul>
            </div>
            <Link style={{width:'100px',wordWrap:'break-word',marginLeft:'0'}}  role='button' className='nav-text-home nav-link ' to="/contact">സമീപിക്കുക</Link>
          </Navn>
        </Navbart.Collapse>
      </Navbart>
    </div>
  );
}
