import React from "react";
import { Container, Row, Col } from "react-bootstrap";
 import "./whatsnew.css";
import { Link } from "react-router-dom";

export default function WhatsNew({ regulations, orders }) {
  return (
   <div className="whatsnew1">
      <Container className="whatsnew ">
        <Row className="whats-new-row">
          <Col lg={6} md={12}>
            <div className="news">
              <h3 className="news-text-whats mt-5 ">What's New</h3>
            </div>
            <div className="whats-now-box mb-5">
              <ul>
                {regulations.map((regulation, index) => (
                  <li
                    className={
                      index % 2 === 0
                        ? "whats-new-text-1"
                        : "whats-new-text-2"
                    }
                    key={index}
                  >
                    <Link to={regulation.link}>{regulation.text}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
  
          <Col lg={6} md={12}>
            <div className="news" id="news2">
              <h3 className="news-text-whats mt-5">All Orders</h3>
            </div>
            <div className="whats-now-box mb-5">
              <ul>
                {orders.map((order, index) => (
                  <li
                    className={
                      index % 2 === 0
                        ? "whats-new-text-1"
                        : "whats-new-text-2"
                    }
                    key={index}
                  >
                    <Link to={order.link}>{order.text}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
   </div>
  );
}
