import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import TabArchives from 'react-bootstrap/Tab';
import TabsTabArchives from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import FloatingLabelSerch from 'react-bootstrap/FloatingLabel';
import './regulationspage.css';
import { Link } from "react-router-dom";
import APIService from '../../APIService';

export default function Regulationspage() {

  const regulationTypes = [
    "Tariff Regulations",
    "Conduct of Business Regulations",
    "CGRF and Ombudsman Regulations",
    "Licensing Regulations",
    "Renewable Energy Regulations",
    "Distribution Licensee Regulations",
    "Generating companies Regulations",
    "Connectivity and Open Access Regulations",
    "Electricity Supply Code and Amendments",
    "Electricity Grid Code and Amendments",
    "Compliance and Audit Regulations",
    "Administrative Regulations",
    "Miscellaneous Regulations",
  ];

  const [draftData, setDraftData] = useState([]);
  const [finaltData, setFinaltData] = useState([]);

  const [filteredData, setFilteredData] = useState([]);
  const [filteredFinalData, setFilteredFinalData] = useState([]);

  const [searchItem, setSearchItem] = useState("");
  const [filterYear, setFilterYear] = useState("");

  const [filterYearFinal, setFilterYearFinal] = useState("");
  const [selectedFinalRegulationType, setSelectedFinalRegulationType] = useState("All Regulations");

  useEffect(() => {
    APIService.get('draft-regulations')
      .then((response) => {
        setDraftData(response.data);
        setFilteredData(response.data);
      })
  }, []);

  useEffect(() => {
    APIService.get('final-regulations')
      .then((response) => {
        setFinaltData(response.data);
        setFilteredFinalData(response.data);
      })
  }, []);

  const handleSearchInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);
    const filteredItems = draftData.filter((data) =>
      data.description.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filteredItems);
  }

  const handleYearFilterChange = (e) => {
    const searchTerm = e.target.value;
    setFilterYear(searchTerm);
    const filteredItems = draftData.filter((data) =>
      data.year.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filteredItems);
  }

  const handleYearFilterChangeFinal = (e) => {
    const searchTerm = e.target.value;
    setFilterYearFinal(searchTerm);
    const filteredItems = finaltData.filter((data) =>
      data.year.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredFinalData(filteredItems);
  }

  const handleFinalRegulationTypeChange = (e) => {
    const selectedType = e.target.value;
    setSelectedFinalRegulationType(selectedType);
    const filteredItems = selectedType === "All Regulations"
      ? finaltData
      : finaltData.filter((data) => data.option === selectedType);
    setFilteredFinalData(filteredItems);
  }

  // Utility function to generate years dynamically
  const generateYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= 2001; year--) {
      years.push(year.toString());
    }
    return years;
  }

  return (
    <div>
      <Container fluid className='right-to-information'>
        <Row>
          <Col md={12}>
            <div className="organisation-img">
              <h1 className='organisation-head'>Regulations</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <div className='Regulations'>
        <Row className='mt-4'>
          <Col md={12}>
            <TabsTabArchives
              defaultActiveKey="home"
              transition={false}
              id="noanim-tab-example"
              className="mb-3">
              <TabArchives eventKey="home" title="  Draft Regulations">
                <div className='archive-scroll'>
                  <div className='draft-regulation-search-area'>
                    <Row className='reg-row' id='regulation-row'>
                      <Col md={4} id="year-tab1">
                        <FloatingLabel
                          controlId="floatingSelectGrid"
                          label="">
                          <Form.Select
                            aria-label="Default select example"
                            value={filterYear}
                            onChange={handleYearFilterChange}
                          >
                            <option value="">Year</option>
                            {generateYears().map((year, index) => (
                              <option key={index} value={year}>{year}</option>
                            ))}
                          </Form.Select>
                        </FloatingLabel>
                      </Col>
                      <Col md={4} className='form-aling' id='reg-form-align'>
                        <FloatingLabelSerch className='Search-aling1' controlId="floatingInputGrid" label="Keyword Search..">
                          <Form.Control type="text"
                            placeholder="Search"
                            value={searchItem}
                            onChange={handleSearchInputChange}
                          />
                        </FloatingLabelSerch>
                        {/* <button type="button" className="btn btn-outline-primary" id='search-btn-reg1'>Search</button> */}
                      </Col>
                      <Col md={4} id='search-btn1'  style={{width:'250px' ,textAlign: 'center' }}> <button style={{width:'100%'}} type="button" className="btn btn-outline-primary " >Search</button></Col>
                    </Row>
                  </div>
                  <div className='overflow-table'>
                    <Table striped bordered hover variant="light" id='draft-regulation-table'>
                      <thead>
                        <tr>
                          <th className='table-no-aling'>SI.No. </th>
                          <th>Description</th>
                          <th>Year</th>
                          <th>English</th>
                          <th>Malayalam</th>
                          <th>Gazatte</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData.map((data, index) => (
                          <tr key={data.id}>
                            <td className='table-no-aling' >{index + 1}</td>
                            <td>{data.description}</td>
                            <td>{data.year}</td>
                            <td>
                              <div className='ordrs-icon-aling'>
                                <Link to={data.english} target='blank'>view</Link>
                              </div>
                            </td>
                            <td>
                              <div className='ordrs-icon-aling'>
                                <Link to={data.malayalam} target='blank'>view</Link>
                              </div>
                            </td>
                            <td>
                              <div className='ordrs-icon-aling'>
                                <Link to={data.gazette} target='blank'>view</Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </TabArchives>

              <TabArchives eventKey="Draft Regulations" title="Final Regulations">
                <div className='dropdown-wrapper'>
                  <Row>
                    <Form.Label column lg={2} id='regulation-label'>
                      <h6>Regulation Type</h6>
                    </Form.Label>
                    <Form.Group as={Col} controlId="formGridState" >
                      <Form.Select id='dropdown-area'
                        aria-label="Default select example"
                        value={selectedFinalRegulationType}
                        onChange={handleFinalRegulationTypeChange}
                      >
                        <option>All Regulations</option>
                        {regulationTypes.map((type, index) => (
                          <option key={index}>{type}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Row>

                  <Row id='regulation-year'>
                    <Form.Label column lg={2} id='regulation-label'>
                      <h6>Year</h6>
                    </Form.Label>
                    <Form.Group as={Col} controlId="formGridState" >
                      <Form.Select id='dropdown-year'
                        aria-label="Default select example"
                        value={filterYearFinal}
                        onChange={handleYearFilterChangeFinal}
                      >
                        <option>Choose...</option>
                        {generateYears().map((year, index) => (
                          <option key={index} value={year}>{year}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Row>
                  <button type="button" className="btn btn-outline-primary" id='search-btn-finalreg'>Submit</button>
                </div>

                {regulationTypes.map((type, index) => (
                  <div className='final-reg-table' key={"div_" + index} >
                    <h3 id='table-heading-final-regulations'>{type}</h3>
                    <div className='table-responsive'>
                      <Table striped bordered hover variant="light">
                        <thead>
                          <tr>
                            <th className='table-no-aling'>SL.No.</th>
                            <th>Description</th>
                            <th>Year</th>
                            <th>English</th>
                            <th>Malayalam</th>
                            <th>Gazatte</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredFinalData
                            .filter(data => data.option === type)
                            .map((data, index) => (
                              <tr key={data.id}>
                                <td className='table-no-aling' >{index + 1}</td>
                                <td>{data.description}</td>
                                <td>{data.year}</td>
                                <td>
                                  <div className='ordrs-icon-aling'>
                                    <Link to={data.english} target='blank'>view</Link>
                                  </div>
                                </td>
                                <td>
                                  <div className='ordrs-icon-aling'>
                                    <Link to={data.malayalam} target='blank'>view</Link>
                                  </div>
                                </td>
                                <td>
                                  <div className='ordrs-icon-aling'>
                                    <Link to={data.gazette} target='blank'>view</Link>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                ))}
              </TabArchives>
            </TabsTabArchives>
          </Col>
        </Row>
      </div>
    </div >
  )
}
